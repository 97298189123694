import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ScrollArea } from "@/components/ui/scroll-area";

import { useRecoilState } from 'recoil';
import { tokenAtom } from 'core/config/atoms';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import Lottie from 'lottie-react';
import { toast } from 'react-toastify';

import ServiceFranchise from 'services/serviceFranchise';

import animBtnLoading from '../../../assets/anim/anim-btnLoading.json';

export default function PanelFranchiseEdit() {

    const [token] = useRecoilState(tokenAtom);

    const { id } = useParams();

    const [submit, setSubmit] = useState(false);

    const navigate = useNavigate();
    const franchiseService = new ServiceFranchise();

    const goBack = () => {
        navigate(`/home/franchise`);
    }

    const formVSchema = Yup.object().shape({
        name: Yup.string().required('This information is required'),
        tname: Yup.string().required('This information is required'),
        shortCode: Yup.string().required('This information is required'),
        email: Yup.string().email('Please enter valid email address'),
        phone: Yup.string().matches(/^[0-9]{10}$/, { message: 'Please enter valid phone number' }).required('This information is required'),
        gstn: Yup.string().matches(/^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}$/, { message: 'Please enter valid GSTN number' }).required('This information is required'),
        address: Yup.string().required('This information is required'),
    });

    const { values, errors, touched, setValues, handleChange, handleSubmit, handleReset } = useFormik({
        initialValues: {
            name: '',
            tname: '',
            shortCode: '',
            email: '',
            phone: '',
            gstn: '',
            address: '',
        },
        enableReinitialze: true,
        validationSchema: formVSchema,
        onSubmit: values => {
            setSubmit(true);
            let body = {
                "fid": id,
                "data": {
                    "name": values.name,
                    "tradNme": values.tname,
                    "title": values.shortCode,
                    "email": values.email,
                    "phone": values.phone,
                    "gstn": values.gstn,
                    "Addr": values.address,
                }
            }
            franchiseService.updateFranchiseById(body, token).then((res) => {
                if (res.status) {
                    setSubmit(false);
                    toast.success("Franchise updated!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    goBack();
                } else {
                    setSubmit(false);
                    toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                }
            });
        },
    });

    const loadData = () => {
        (async function () {
            let body = {
                "fid": id,
            };
            var res = await franchiseService.getFranchiseById(body, token);
            let user = res.data;
            setValues({
                ...values, name: user.name, tname: user.tradNme, shortCode: user.title, email: user.email, phone: user.phone, gstn: user.gstn, address: user.Addr
            });
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <ScrollArea className="w-full h-screen p-2 flex flex-col overflow-auto">
            <div className="p-3">
                <form onSubmit={handleSubmit}>
                    <div className="intro-y flex items-center cursor-pointer" onClick={() => { goBack() }}>
                        <i className="las la-long-arrow-alt-left text-xl"></i>
                        <h4 className="text-xs text-black font-sans font-medium ml-2">Back to all franchise</h4>
                    </div>
                    <div className="intro-y mt-4">
                        <h3 className="text-2xl font-sans font-bold text-gray-800">Edit Franchises</h3>
                        <p className="mt-1 text-sm text-gray-600 mb-6">
                            Please enter corret details and information related to franchise.
                        </p>
                        <hr />
                    </div>
                    <div className="mt-4">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="intro-y mt-5 md:mt-0 md:col-span-12">
                                <div className="shadow">
                                    <div className="px-4 py-5 bg-white sm:p-6 rounded-lg">
                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6">
                                                <h3 className="text-lg font-medium leading-6 text-gray-900">Franchise Information</h3>
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Franchise name<sup className="text-red-600">*</sup></label>
                                                <input type="text" id="name" value={values.name} onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="i.e. BookTable" />
                                                {(errors.name && touched.name) && <p className='text-xs text-red-400 mt-1'>{errors.name}</p>}
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Franchise Trade name<sup className="text-red-600">*</sup></label>
                                                <input type="text" id="tname" value={values.tname} onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="i.e. BookTable" />
                                                {(errors.tname && touched.tname) && <p className='text-xs text-red-400 mt-1'>{errors.tname}</p>}
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Franchise Location Code<sup className="text-red-600">*</sup></label>
                                                <input type="text" id="shortCode" value={values.shortCode} onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="i.e. location A" />
                                                {(errors.shortCode && touched.shortCode) && <p className='text-xs text-red-400 mt-1'>{errors.shortCode}</p>}
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Email address<sup className="text-red-600">*</sup></label>
                                                <input type="text" id="email" value={values.email} onChange={handleChange} autoComplete="email" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="Email address" />
                                                {(errors.email && touched.email) && <p className='text-xs text-red-400 mt-1'>{errors.email}</p>}
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Phone number<sup className="text-red-600">*</sup></label>
                                                <input type="text" id="phone" value={values.phone} onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="i.e. 012345689" maxLength="10" minLength="10" />
                                                {(errors.phone && touched.phone) && <p className='text-xs text-red-400 mt-1'>{errors.phone}</p>}
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">GSTN<sup className="text-red-600">*</sup></label>
                                                <input type="text" id="gstn" value={values.gstn} onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="i.e. 27AAAAA1234AAZM" />
                                                {(errors.gstn && touched.gstn) && <p className='text-xs text-red-400 mt-1'>{errors.gstn}</p>}
                                            </div>

                                            <div className="col-span-6">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Physical address 1<sup className="text-red-600">*</sup></label>
                                                <textarea id="address" value={values.address} onChange={handleChange} autoComplete="street-address" className="mt-1 p-2 block w-full h-20 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="Street address"></textarea>
                                                {(errors.address && touched.address) && <p className='text-xs text-red-400 mt-1'>{errors.address}</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="intro-y px-2 py-3 bg-gray-100 text-right">
                        <button type="submit" disabled={submit} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-prime hover:bg-primeLight">
                            {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                            {!submit && "SAVE STAFF"}
                        </button>
                    </div>
                </form>
            </div >
        </ScrollArea>
    )
}
