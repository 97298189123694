import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceFranchise {
    lookupService = new APIServiceLookUp();

    async setFranchise(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}franchise/setFranchise`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getAllFranchise(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}franchise/getAllFranchise`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateFranchiseById(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}franchise/updateFranchise`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async deleteFranchiseById(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}franchise/deleteFranchiseById`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getFranchiseById(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}franchise/getFranchiseById`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getFranchiseOptions(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}franchise/getFranchiseOptions`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

}