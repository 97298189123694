import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ScrollArea } from "@/components/ui/scroll-area";

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../core/config/atoms';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import Lottie from 'lottie-react';
import { toast } from 'react-toastify';

import ServiceStaff from 'services/serviceStaff';
import ServiceFranchise from 'services/serviceFranchise';

import animBtnLoading from '../../assets/anim/anim-btnLoading.json';

export default function PanelStaffAdd() {

    const [franchises, setFranchises] = useState([]);

    const [token] = useRecoilState(tokenAtom);

    const [submit, setSubmit] = useState(false);

    const navigate = useNavigate();
    const staffService = new ServiceStaff();
    const franchiseService = new ServiceFranchise();

    const userTypes = [
        { value: 'ADMIN', label: 'Admin' },
        { value: 'MANAGER', label: 'Manger' },
        { value: 'BILLDESK', label: 'Billing Desk' },
        { value: 'RECIPIENT', label: 'Recipient Desk' },
        { value: 'KITCHEN', label: 'Kitchen Desk' },
        { value: 'STAFF', label: 'Staff' },
    ];

    const goBack = () => {
        navigate(`/home/staff`);
    }

    const getUID = () => {
        return "U" + Math.floor(Date.now() / 1000);
    }

    const formVSchema = Yup.object().shape({
        fid: Yup.string().required('This information is required'),
        firstName: Yup.string().required('This information is required'),
        middianName: Yup.string().required('This information is required'),
        lastName: Yup.string().required('This information is required'),
        email: Yup.string().required('This information is required').email('Please enter valid email address'),
        phone: Yup.string().required('This information is required').matches(/^\d{10}$/, 'Phone number must be 10 digits'),
        uType: Yup.string().required('This information is required'),
        password: Yup.string().required('This information is required'),
    });

    const { values, errors, touched, handleChange, handleSubmit, handleReset } = useFormik({
        initialValues: {
            fid: '',
            firstName: '',
            middianName: '',
            lastName: '',
            email: '',
            phone: '',
            uType: '',
            password: '',
        },
        enableReinitialze: true,
        validationSchema: formVSchema,
        onSubmit: values => {
            setSubmit(true);
            let body = {
                "fname": values.firstName,
                "mname": values.middianName,
                "lname": values.lastName,
                "email": values.email,
                "phone": values.phone,
                "role": values.uType,
                "password": values.password,
                "dob": "",
                "address": [],
                "status": "active",
                "fid": values.fid,
                "uid": getUID()
            }
            staffService.setStaff(body, token).then((res) => {
                if (res.status) {
                    setSubmit(false);
                    toast.success("New staff added!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    goBack();
                } else {
                    setSubmit(false);
                    toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                }
            });
        },
    });

    const loadData = () => {
        (async function () {
            var res = await franchiseService.getFranchiseOptions({}, token);
            setFranchises(res.data);
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <ScrollArea className="w-full h-screen p-2 flex flex-col overflow-auto">
            <div className="p-3">
                <form onSubmit={handleSubmit}>
                    <div className="intro-y flex items-center cursor-pointer" onClick={() => { goBack() }}>
                        <i className="las la-long-arrow-alt-left text-xl"></i>
                        <h4 className="text-xs text-black font-sans font-medium ml-2">Back to all staff</h4>
                    </div>
                    <div className="intro-y mt-4">
                        <h3 className="text-2xl font-sans font-bold text-gray-800">Add Staff</h3>
                        <p className="mt-1 text-sm text-gray-600 mb-6">
                            Please enter corret details and information related to staff.
                        </p>
                        <hr />
                    </div>
                    <div className="mt-4">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="intro-y mt-5 md:mt-0 md:col-span-12">
                                <div className="shadow">
                                    <div className="px-4 py-5 bg-white sm:p-6 rounded-lg">
                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6">
                                                <h3 className="text-lg font-medium leading-6 text-gray-900">Staff Information</h3>
                                            </div>

                                            <div className="col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Franchise<sup className="text-red-600">*</sup></label>
                                                <select id="fid" className="border-gray-300 border rounded appearance-none w-full py-1 px-2 bg-white mt-2" onChange={handleChange} value={values.fid}>
                                                    <option value="" disabled selected>Select Franchise</option>
                                                    {
                                                        franchises.length !== 0 && franchises.map((item) => {
                                                            return (
                                                                <option key={item.fid} value={item.fid}>
                                                                    {item.title} - {item.fid}
                                                                </option>
                                                            );
                                                        })
                                                    }
                                                </select>
                                                {(errors.uType && touched.uType) && <p className='text-xs text-red-400 mt-1'>{errors.uType}</p>}
                                            </div>

                                            <div className="col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Staff Type<sup className="text-red-600">*</sup></label>
                                                <select id="uType" className="border-gray-300 border rounded appearance-none w-full py-1 px-2 bg-white mt-2" onChange={handleChange} value={values.uType}>
                                                    <option value="" disabled="" selected="selected">Select</option>
                                                    {
                                                        userTypes.length !== 0 && userTypes.map((item) => {
                                                            return <option value={item.value} selected={values.uType == item.value}>{item.label}</option>
                                                        })
                                                    }
                                                </select>
                                                {(errors.uType && touched.uType) && <p className='text-xs text-red-400 mt-1'>{errors.uType}</p>}
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">First name<sup className="text-red-600">*</sup></label>
                                                <input type="text" id="firstName" onChange={handleChange} autoComplete="given-name" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="First name" value={values.firstName} />
                                                {(errors.firstName && touched.firstName) && <p className='text-xs text-red-400 mt-1'>{errors.firstName}</p>}
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Middian name<sup className="text-red-600">*</sup></label>
                                                <input type="text" id="middianName" onChange={handleChange} autoComplete="family-name" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Middian name" value={values.middianName} />
                                                {(errors.middianName && touched.middianName) && <p className='text-xs text-red-400 mt-1'>{errors.middianName}</p>}
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Last name<sup className="text-red-600">*</sup></label>
                                                <input type="text" id="lastName" onChange={handleChange} autoComplete="family-name" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Last name" value={values.lastName} />
                                                {(errors.lastName && touched.lastName) && <p className='text-xs text-red-400 mt-1'>{errors.lastName}</p>}
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Email address</label>
                                                <input type="text" id="email" onChange={handleChange} autoComplete="email" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="Email address" value={values.email} />
                                                {(errors.email && touched.email) && <p className='text-xs text-red-400 mt-1'>{errors.email}</p>}
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Phone number</label>
                                                <input type="text" id="phone" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="Phone number" value={values.phone} />
                                                {(errors.phone && touched.phone) && <p className='text-xs text-red-400 mt-1'>{errors.phone}</p>}
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Password<sup className="text-red-600">*</sup></label>
                                                <input type="text" id="password" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. xxxxxxxxxxx" value={values.password} />
                                                {(errors.password && touched.password) && <p className='text-xs text-red-400 mt-1'>{errors.password}</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="intro-y px-2 py-3 bg-gray-100 text-right">
                        <button type="submit" disabled={submit} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-prime hover:bg-primeLight">
                            {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                            {!submit && "ADD STAFF"}
                        </button>
                    </div>
                </form>
            </div >
        </ScrollArea>
    )
}
